import { Reference } from "../reference-interface"
import { arcedulaInfoCards } from "../../service-info-card-interface/used_by/arcedula-cards"

export const Arcedula : Reference = {
  monitorPic: "/images/refs/arcedula/arcedula_mac.png",
  siteName: "Árcédula",
  siteDescription: "Az Árcédula egy árösszehasonlító oldal, amelynek mind a látogatói oldal mind a partner oldal kialakítását megvalósítottuk és üzemeltetjük.",
  siteUrl: "https://www.arcedula.hu",
  cards: arcedulaInfoCards,
  referencePics: [
    "/images/refs/arcedula/arcedula_screenshot_2.png",
    "/images/refs/arcedula/arcedula_card.png",
    "/images/refs/arcedula/arcedula_screenshot_1.png",
  ],
  pageFunctions: [
    "Reszponzív design",
    "Árösszehasonlítás",
  ],
  reversePageFunctions: false,
}
