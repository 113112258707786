import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const arcedula1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "Mindig izgalmas kihívás amikor nagy mennyiségű adat feldolgozásával kell foglalkozni. Az Árcédulánál különösen fontos volt, hogy a partnerek által beküldött termékek a lehető leghamarabb frissüljenek. Számunkra ez egy nagyon élvezetes projekt volt megvalósítani és azóta is folyamatosan üzemeltetni.",
  displayXo: true,
  reverse: true,
  title: "Sok-sok adat",
  picture: "/images/refs/arcedula/arcedula_mac.png",
  boldDescription: ''
}




export const arcedulaInfoCards = [
  arcedula1,
]

